<template>
  <stemble-latex :content="latexContent" :responsive="responsive" />
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemicalLatex',
  components: {StembleLatex},
  props: {
    content: {
      type: String,
      required: true,
    },
    responsive: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    latexContent() {
      return `$\\ce{${this.content}}$`;
    },
  },
};
</script>
