<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine whether each of the following compounds is <b>ionic</b> or <b>molecular</b>:
      </p>

      <v-simple-table class="pl-8" style="max-width: 450px">
        <thead>
          <tr>
            <th style="text-align: left; font-size: 15px">System</th>
            <th style="text-align: left; font-size: 15px">Compound Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(molecule, i) in molecules" :key="molecule">
            <td style="vertical-align: middle">
              <chemical-latex :content="molecule" />
            </td>
            <td>
              <v-radio-group v-model="inputs['input' + (i + 1)]">
                <div v-for="option in options" :key="option.value">
                  <v-radio :value="option.value">
                    <template #label>
                      <stemble-latex :content="option.text" />
                    </template>
                  </v-radio>
                </div>
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question54',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {text: '$\\text{Ionic}$', value: 'ionic'},
        {text: '$\\text{Molecular}$', value: 'molecular'},
      ],
      molecules: ['FeCl3', 'SO2', 'SiH4', 'KBr'],
    };
  },
};
</script>
